import router from 'next/router'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Column, IdType, useFlexLayout, useTable } from 'react-table'

import { LINKS } from 'constants/links'

import { Divider } from 'driverama-core/components/divider/Divider'
import { TextBody, TextBodyBold } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'

import { css, SerializedStyles } from '@emotion/react'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import {
  SCol,
  SRow,
  STableContainer,
  STableHeader,
  STableWrapper
} from './InfiniteTable.styled'

export type TableColumn<T extends Record<string, unknown>> = Column<T> & {
  align?: 'center' | 'left' | 'right'
}

type Props<T extends Record<string, unknown>> = {
  columns: TableColumn<T>[]
  data: T[]
  hasMoreRecords: boolean
  loadMoreRecords?: () => void
  heightCorrection?: number
  disabledRowClick?: boolean
  headerStyles?: SerializedStyles
  hiddenColumns?: IdType<T>[]
  isLoading?: boolean
}

export const COLUMN_WIDTH = 100

export function InfiniteTable<T extends Record<string, unknown>>({
  columns,
  data,
  loadMoreRecords,
  hasMoreRecords,
  disabledRowClick = false,
  heightCorrection,
  headerStyles,
  hiddenColumns,
  isLoading
}: Props<T>) {
  const { t } = useTranslation(['core'])

  const handleLoadMore = () => {
    loadMoreRecords?.()
  }

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: COLUMN_WIDTH, // width is used for both the flex-basis and flex-grow
      maxWidth: 200 // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable<T>(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenColumns ?? ['id']
      }
    },
    useFlexLayout
  )

  const tableCols = useMemo(
    () =>
      headerGroups.map(headerGroup => (
        <div {...headerGroup.getHeaderGroupProps()} className="tr">
          {headerGroup.headers.map(column => (
            <SCol
              {...column.getHeaderProps()}
              className="th"
              textAlign={(column as TableColumn<T>)?.align}
            >
              {typeof column.Header === 'string' ? (
                <TextBodyBold variant="caption">
                  {column.render('Header')}
                </TextBodyBold>
              ) : (
                column.render('Header')
              )}
            </SCol>
          ))}
        </div>
      )),
    [headerGroups]
  )

  const tableRows = useMemo(() => {
    return rows.map(row => {
      prepareRow(row)

      return (
        <SRow
          className="tr"
          {...row.getRowProps()}
          onClick={
            disabledRowClick
              ? undefined
              : () => router.push(LINKS.carDetail(row.values['id']))
          }
        >
          {row.cells.map(cell => {
            return (
              <SCol
                className="td"
                {...cell.getCellProps()}
                textAlign={(cell.column as TableColumn<T>)?.align}
              >
                {cell.render('Cell')}
              </SCol>
            )
          })}
        </SRow>
      )
    })
  }, [prepareRow, rows, disabledRowClick])

  return (
    <STableContainer>
      <STableWrapper id="scrollableDiv" heightCorrection={heightCorrection}>
        <div className="table" {...getTableProps()}>
          <STableHeader css={headerStyles}>
            <div className="thead">{tableCols}</div>
            <Divider variant="light" />
          </STableHeader>

          {isLoading ? (
            <SpinnerCentered
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                bottom: -10px;
                left: 0;
              `}
            />
          ) : (
            <div className="tbody" {...getTableBodyProps()}>
              <InfiniteScroll
                style={{ overflow: 'hidden' }}
                dataLength={rows.length}
                next={handleLoadMore}
                hasMore={hasMoreRecords}
                scrollableTarget="scrollableDiv"
                scrollThreshold={0.5}
                loader={
                  <TextBody css={{ margin: size(2) }} variant="caption">
                    {t('core:table_load_more_items')}
                  </TextBody>
                }
              >
                {tableRows}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </STableWrapper>
    </STableContainer>
  )
}

import styled from '@emotion/styled'
import { fadeIn } from 'driverama-core/styles/animations'
import { styledScrollbars } from 'driverama-core/styles/common'
import { size } from 'driverama-core/styles/spacing'
import { color, time } from 'driverama-core/styles/variables'

export const STableContainer = styled.div`
  display: flex;

  animation: ${fadeIn} ${time('control')} ease-in-out;

  .table {
    width: fit-content;
    min-width: 100% !important;
  }

  .thead {
    padding: ${size(2)} ${size(3)};
  }

  th,
  td {
    vertical-align: middle;
    width: 1%;
  }

  .th span {
    color: ${color('night-l-200')};
  }
`

export const SRow = styled.div`
  padding: ${size(3)};
  align-items: center;
  font-size: 13px;

  border-bottom: 2px solid ${color('night-l-700')};

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${color('night-l-800')};
    }
  }
`

export const STableWrapper = styled.div<{ heightCorrection?: number }>`
  --margin-vertical: ${size(8)};

  position: relative;
  display: block;

  min-width: 100%;
  width: fit-content;

  overflow: auto;

  ${styledScrollbars}
`

export const STableHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: ${color('night-l-700')};
`

export const SLink = styled.a`
  width: 100%;
`

export const SCol = styled.div<{
  textAlign?: 'left' | 'center' | 'right'
}>`
  text-align: ${({ textAlign = 'left' }) => textAlign};
`
